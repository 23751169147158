<template>
  <div class="organization backMenu">
    <div class="structure-tree">
      <div class="addone" v-if="allJurisdiction.organization104">
        <img
          src="@/assets/img/add.png"
          alt=""
          @click="addSonBTn(0, 0)"
        />新建机构
      </div>
      <div class="tree-box">
        <div class="tree">
          <div
            class="head"
            @click="choseMenu(null)"
            @mouseleave="closemore()"
            @mouseover="mouseOver(0)"
          >
            <input
              type="text"
              v-if="rechristenId == topLeave.id"
              class="change-first"
              @blur="RNFR"
              v-model="mechanismName"
            />
            <span v-else>{{ topLeave.mechanismname }}</span>
            <img
              src="@/assets/img/more.png"
              alt=""
              class="more"
              @click.stop="openmore(0)"
              v-if="overId == 0"
            />
            <div class="more-function" v-if="moreId == 0">
              <el-button
                @click="rechristen(0, null)"
                v-if="allJurisdiction.organization106"
                >重命名</el-button
              >
            </div>
          </div>
          <input
            type="text"
            v-if="addSecond == 0"
            class="first-input"
            @blur="addSon"
            v-model="mechanismName"
          />

          <!-- 一级菜单 -->
          <div class="first" v-for="item in tree" :key="item.id">
            <div
              class="first-list"
              :class="choseId == item.id ? 'chose' : ''"
              @click="choseMenu(item.id)"
              @mouseleave="closemore()"
              @mouseover="mouseOver(item.id)"
            >
              <div>
                <img
                  src="@/assets/img/nabla.png"
                  alt=""
                  class="nabla"
                  @click="openMenu(item.id, 0)"
                  v-if="
                    item.platformmechanismlist.length > 0 &&
                    item.id != isOpen[0]
                  "
                />
                <img
                  src="@/assets/img/nabla.png"
                  alt=""
                  class="nabla rotate"
                  @click="openMenu(item.id, 0)"
                  v-if="
                    item.platformmechanismlist.length > 0 &&
                    item.id == isOpen[0]
                  "
                />
                <div
                  v-if="item.platformmechanismlist.length == 0"
                  class="none-son"
                ></div>
                <input
                  type="text"
                  v-if="rechristenId == item.id"
                  class="change-first"
                  @blur="RNFR"
                  v-model="mechanismName"
                />
                <span v-else>{{ item.mechanismname }}</span>
              </div>
              <img
                src="@/assets/img/more.png"
                alt=""
                class="more"
                @click.stop="openmore(item.id)"
                v-if="overId == item.id"
              />
              <div class="more-function" v-if="item.id == moreId">
                <el-button
                  @click="addSonBTn(item.id, 0)"
                  v-if="allJurisdiction.organization104"
                  >新建子机构</el-button
                >
                <el-button
                  @click="rechristen(item, topLeave.id)"
                  v-if="allJurisdiction.organization106"
                  >重命名</el-button
                >
                <el-button
                  @click.stop="openMove(item, 0)"
                  v-if="allJurisdiction.organization107"
                  >移动到</el-button
                >
                <el-button
                  @click="del(item, 0)"
                  v-if="allJurisdiction.organization105"
                  >删除</el-button
                >
                <div class="move-list" v-if="moveId == item.id">
                  <MoveList
                    :tree="tree"
                    :moveId="moveId"
                    :topLeave="topLeave"
                  />
                </div>
              </div>
            </div>
            <div v-if="item.id == isOpen[0]">
              <input
                type="text"
                v-if="addSecond == item.id"
                class="second-input"
                @blur="addSon"
                v-model="mechanismName"
              />
              <!-- 二级菜单 -->
              <div
                class="second"
                v-for="val in item.platformmechanismlist"
                :key="val.id"
              >
                <div
                  class="second-list"
                  :class="choseId == val.id ? 'chose' : ''"
                  @click="choseMenu(val.id)"
                  @mouseleave="closemore()"
                  @mouseover="mouseOver(val.id)"
                >
                  <div>
                    <img
                      src="@/assets/img/nabla.png"
                      alt=""
                      class="nabla"
                      @click.stop="openMenu(item.id, val.id)"
                      v-if="
                        val.platformmechanismlist.length > 0 &&
                        item.id == isOpen[0] &&
                        val.id != isOpen[1]
                      "
                    />
                    <img
                      src="@/assets/img/nabla.png"
                      alt=""
                      class="nabla rotate"
                      @click="openMenu(item.id, val.id)"
                      v-if="
                        val.platformmechanismlist.length > 0 &&
                        item.id == isOpen[0] &&
                        val.id == isOpen[1]
                      "
                    />
                    <div
                      v-if="val.platformmechanismlist.length == 0"
                      class="none-son"
                    ></div>
                    <input
                      type="text"
                      v-if="rechristenId == val.id"
                      class="change-second"
                      @blur="RNFR"
                      v-model="mechanismName"
                    />
                    <span v-else>{{ val.mechanismname }}</span>
                  </div>
                  <img
                    src="@/assets/img/more.png"
                    alt=""
                    class="more"
                    @click.stop="openmore(val.id)"
                    v-if="overId == val.id"
                  />
                  <div class="more-function" v-if="val.id == moreId">
                    <el-button
                      @click="addSonBTn(item.id, val.id)"
                      v-if="allJurisdiction.organization104"
                      >新建子机构</el-button
                    >
                    <el-button
                      @click="rechristen(val, item.id)"
                      v-if="allJurisdiction.organization106"
                      >重命名</el-button
                    >
                    <el-button
                      @click.stop="openMove(val, 0)"
                      v-if="allJurisdiction.organization107"
                      >移动到</el-button
                    >
                    <el-button
                      @click="del(val, 0)"
                      v-if="allJurisdiction.organization105"
                      >删除</el-button
                    >
                    <div class="move-list" v-if="moveId == val.id">
                      <MoveList
                        :tree="tree"
                        :moveId="moveId"
                        :topLeave="topLeave"
                      />
                    </div>
                  </div>
                </div>

                <div v-if="val.id == isOpen[1]">
                  <input
                    type="text"
                    name=""
                    id=""
                    v-if="addSecond == val.id"
                    class="second-input"
                    @blur="addSon"
                    v-model="mechanismName"
                  />
                  <!-- 三级菜单 -->
                  <div
                    class="three"
                    v-for="v in val.platformmechanismlist"
                    :key="v.id"
                  >
                    <div
                      class="three-list"
                      :class="choseId == v.id ? 'chose' : ''"
                      @click="choseMenu(v.id)"
                      @mouseleave="closemore()"
                      @mouseover="mouseOver(v.id)"
                    >
                      <div class="three-dis">
                        <div class="none-son"></div>
                        <input
                          type="text"
                          v-if="rechristenId == v.id"
                          class="change-second"
                          @blur="RNFR"
                          v-model="mechanismName"
                        />
                        <span v-else>{{ v.mechanismname }}</span>
                      </div>

                      <img
                        src="@/assets/img/more.png"
                        alt=""
                        class="more"
                        @click.stop="openmore(v.id)"
                        v-if="overId == v.id"
                      />
                      <div class="more-function" v-if="v.id == moreId">
                        <el-button
                          @click="rechristen(v, val.id)"
                          v-if="allJurisdiction.organization106"
                          >重命名</el-button
                        >
                        <el-button
                          @click.stop="openMove(v, 0)"
                          v-if="allJurisdiction.organization107"
                          >移动到</el-button
                        >
                        <el-button
                          @click="del(v, 0)"
                          v-if="allJurisdiction.organization105"
                          >删除</el-button
                        >
                        <div class="move-list" v-if="moveId == v.id">
                          <MoveList
                            :tree="tree"
                            :moveId="moveId"
                            :topLeave="topLeave"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="staff backMenu">
      <div class="sch">
        <div>
          <el-input
            v-model="api.platformAccountName"
            placeholder="账号/手机号"
            class="sch-input"
            clearable
            maxlength="20"
          ></el-input>
          <!-- <el-input
            v-model="api.mobile"
            placeholder="手机号"
            class="sch-input"
            oninput="value=value.replace(/[^\d]/g,'')" 
            clearable
            @change="seeAbout"
            maxlength="20"
          ></el-input> -->
          <el-select v-model="api.status" placeholder="请选择状态" clearable>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <el-button @click="seeAbout">查询</el-button>
        </div>
        <el-button @click="toAddBack" v-if="allJurisdiction.organization108"
          >创建员工</el-button
        >
      </div>
      <div class="merch-table scorr-roll">
        <div>
          <el-table
            :data="userList.list"
            border
            style="width: 100%"
            header-cell-class-name="table_header"
          >
            <el-table-column prop="id" label="序号" align="center" width="80">
            </el-table-column>
            <el-table-column prop="createtime" label="创建时间" align="center">
            </el-table-column>
            <el-table-column
              prop="staffname"
              label="员工姓名"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="platformaccountname"
              label="绑定账号"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="mobile"
              label="手机号"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="gender"
              label="性别"
              align="center"
              width="80"
            >
              <template slot-scope="{ row }">
                <div class="exceed" v-if="row.gender == 0">男</div>
                <div class="exceed" v-else>女</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="status"
              label="状态"
              align="center"
              width="80"
            >
              <template slot-scope="{ row }">
                <div class="exceed" v-if="row.status == 0">禁用</div>
                <div class="exceed" v-else>正常</div>
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="350"
            >
              <template slot-scope="scope">
                <div class="userBtn">
                  <!--  -->
                  <div
                    @click="openDetails(scope.row)"
                    v-if="allJurisdiction.organization110"
                  >
                    详情
                  </div>
                  <div
                    @click="toBtn(scope.row)"
                    v-if="allJurisdiction.organization109"
                  >
                    编辑
                  </div>
                  <el-popconfirm
                    :title="`确定要启用员工【${scope.row.staffname}】？`"
                    v-if="
                      scope.row.status == 0 && allJurisdiction.organization112
                    "
                    @confirm="onOff(scope.row)"
                  >
                    <el-button slot="reference">启用</el-button>
                  </el-popconfirm>
                  <el-popconfirm
                    :title="`确定要禁用员工【${scope.row.staffname}】？`"
                    v-if="
                      scope.row.status != 0 && allJurisdiction.organization112
                    "
                    @confirm="onOff(scope.row)"
                  >
                    <el-button slot="reference">禁用</el-button>
                  </el-popconfirm>
                  <el-popconfirm
                    :title="`确定要删除员工【${scope.row.staffname}】？删除后当前员工不可恢复，请谨慎操作。`"
                    @confirm="delStaff(scope.row)"
                    v-if="allJurisdiction.organization111"
                  >
                    <el-button slot="reference">删除</el-button>
                  </el-popconfirm>
                  <!-- <div @click="toRedact(scope.row)">禁用</div>
                  <div @click="toRedact(scope.row)">删除</div> -->
                  <!-- <div @click="toAccount">账户</div>
                  <div>详情</div> -->
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          layout="total, prev, pager, next, jumper"
          :total="userList.total"
          :page-size="userList.per_page"
          :current-page="userList.pagenumber"
        >
        </el-pagination>
      </div>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      title="删除机构提醒"
      :visible.sync="dialogVisibleDeleted"
      width="30%"
    >
      <span v-if="this.roleAdminNum == 1"
        >确定要删除机构【{{ dialogName }}】吗？</span
      ><span v-else-if="this.roleAdminNum == 2"
        >你无法删除机构【{{ dialogName }}】，因为当前机构下存在员工。</span
      ><span v-else-if="this.roleAdminNum == 3"
        >你无法删除机构【{{ dialogName }}】，因为当前机构下存在子机构。</span
      >
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="dialogVisibleDeleted = false"
          v-if="this.roleAdminNum == 1"
          >取 消</el-button
        >
        <el-button
          type="primary"
          @click="confirmDel()"
          v-if="this.roleAdminNum == 1"
          >确 定</el-button
        >
        <el-button type="primary" @click="dialogVisibleDeleted = false" v-else
          >知道了</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :title="isAdd ? '创建员工' : '编辑员工'"
      :visible.sync="isAddBackPerson"
      width="600px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="AddMenu scorr-roll">
        <div class="AddMenu-1-box">
          <div>
            <el-form label-width="120px">
              <el-form-item label="姓名:" required>
                <div class="inputbox">
                  <el-input
                    v-model.trim="addData.staffname"
                    placeholder="请输入姓名"
                    class="sch-input"
                    maxlength="20"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="账号:" required>
                <div class="inputbox">
                  <el-select
                    v-model="platformAccount"
                    filterable
                    placeholder="请选择账号"
                  >
                    <el-option
                      v-for="item in account"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item label="机构:" required>
                <div class="inputbox">
                  <el-cascader
                    v-model="mechanismId"
                    :options="institution"
                    :props="{ checkStrictly: true }"
                    :placeholder="placeholderCascader"
                    clearable
                  ></el-cascader>
                </div>
              </el-form-item>
              <el-form-item label="手机号:" required>
                <div class="inputbox">
                  <el-input
                    v-model.trim="addData.mobile"
                    placeholder="请输入手机号"
                    maxlength="11"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    class="sch-input"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item label="性别:" required>
                <div class="inputbox">
                  <el-radio v-model="addData.gender" label="0">男</el-radio>
                  <el-radio v-model="addData.gender" label="1">女</el-radio>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeAddBackPerson()" class="call-off"
          >取 消</el-button
        >
        <el-button type="primary" @click="addAdmin" :disabled="isBtn"
          >保存</el-button
        >
      </span>
      <div class="bubble-box" v-if="vesicle">
        <div class="bubble">
          <i class="el-icon-warning"></i>
          放弃本次<span v-if="isAdd">创建</span
          ><span v-else>编辑</span>账号操作？
          <div>
            <el-button class="cancel" @click="vesicle = false">取消</el-button>
            <el-button class="affirmclose" @click="isAddBackPerson = false"
              >确认</el-button
            >
          </div>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      title="员工详情"
      :visible.sync="dialogDetails"
      width="20%"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
    >
      <div class="dialog-div">姓名：{{ addData.staffname }}</div>
      <div class="dialog-div">账号：{{ addData.platformaccountname }}</div>
      <div class="dialog-div">机构：{{ addData.mechanismname }}</div>
      <div class="dialog-div">手机号：{{ addData.mobile }}</div>
      <div class="dialog-div">
        性别：<span v-if="addData.gender == 0">男</span><span v-else>女</span>
      </div>
      <div class="dialog-div">创建人：{{ addData.creatorname || "/" }}</div>
      <div class="dialog-div">创建时间：{{ addData.createtime || "/" }}</div>
      <div class="dialog-div">最近编辑人：{{ addData.operator || "/" }}</div>
      <div class="dialog-div">
        最近编辑时间：{{ addData.updatetime || "/" }}
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogDetails = false"
          >关闭</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power", "getMenuList"); //权限vuex公共库
export default {
  components: {
    MoveList: () => import("./tree/move.vue"),
    Staff: () => import("./tree/staff.vue"),
  },
  data() {
    return {
      vesicle: false,
      choseId: null,
      isOpen: [0, 0],
      moreId: -1,
      addSecond: -1,
      rechristenId: -1,
      value: [],
      moveId: -1,
      overId: -1,
      tree: [],
      fatherMechanismId: null,
      mechanismName: "", //新建机构名称
      roleAdminNum: 1, //删除条件
      dialogVisibleDeleted: false,
      dialogName: "",
      dialogId: null,
      fatherMechanismId: null,
      userList: {
        list: [],
        total: null,
      },
      api: {
        platformAccountName: "",
        mobile: "",
        status: null,
      },
      options: [
        {
          value: 0,
          label: "禁用",
        },
        {
          value: 1,
          label: "正常",
        },
      ],
      isAdd: true,
      isAddBackPerson: false, //弹框3
      addData: {
        gender: "0",
      },
      distributable: [],
      isBtn: false,
      account: [],
      institution: [],
      mechanismId: [],
      platformAccount: null,

      dialogDetails: false, //账号详情弹出框
      placeholderCascader: "请选择机构",
      TSname: "",
      oldmess: {},
      topLeave: {},
    };
  },
  created() {

    let router=this.$router.history.current.path.substr(1)
    if(this.detailsSeach[router]){
      this.api=this.detailsSeach[router]
      this.choseId=this.detailsSeach[router].platformAccount
      this.userList.pagenumber=this.detailsSeach[router].page
    }
    this.gainpostselectPlatformMechanism();
    this.selectPlatformStaffList();
  },
  computed: {
    ...commonIndex.mapState(["allJurisdiction",
      'detailsSeach']),
  },
  methods: {
    ...mapActions([
      "postselectPlatformMechanism",
      "postaddPlatformMechanism",
      "getdeleteMechanism",
      "postselectPlatformStaff",
      "postrenamingPlatform",
      "postmovePlatform",
      "getallAdmin",
      "postaddPlatformStaff",
      "getselectDetailsStaff",
      "postupdatePlatformStaff",
      "getdisableEnable",
      "getdeleteStaff",
    ]),
    ...commonIndex.mapMutations(['changeSeach']),
    mouseOver(val) {
      this.overId = val;
    },
    // 取消创建和编辑
    closeAddBackPerson() {
      if (this.isAdd) {
        if (
          (this.addData.staffname && this.addData.staffname != "") ||
          (this.platformAccount && this.platformAccount != []) ||
          (this.mechanismId && this.mechanismId.length > 0) ||
          (this.addData.mobile && this.addData.mobile != "") ||
          (this.addData.gender && this.addData.gender != "0")
        ) {
          this.vesicle = true;
        } else {
          this.isAddBackPerson = false;
        }
        // this.isAddBackPerson = true;
      } else {
        if (
          this.addData.staffname == this.oldmess.staffname &&
          this.platformAccount == this.oldmess.platformaccount &&
          (this.mechanismId == this.oldmess.mechanismId ||
            this.mechanismId.length == 0) &&
          this.addData.mobile == this.oldmess.mobile &&
          this.addData.gender == this.oldmess.gender
        ) {
          this.isAddBackPerson = false;
        } else {
          this.vesicle = true;
        }

        // this.isAddBackPerson = true;
      }
    },
    // 删除员工
    async delStaff(val) {
      let data = await this.getdeleteStaff({ id: val.id });
      if (data.code == "200") {
        this.selectPlatformStaffList();
        this.userList.pagenumber = 1;
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 启用/禁用
    async onOff(val) {
      let status = null;
      if (val.status == 0) {
        status = 1;
      } else {
        status = 0;
      }
      let data = await this.getdisableEnable({ id: val.id, status });
      if (data.code == "200") {
        this.selectPlatformStaffList();
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 去编辑
    toBtn(data) {
      this.mechanismId = [];
      this.isAdd = false;
      this.addData = {
        id: data.id,
      };
      this.geteditApi();
      this.gainaccount();
      this.isAddBackPerson = true;
      this.vesicle = false;
    },
    // 查询接口
    async geteditApi() {
      this.mechanismId = [];
      let data = await this.getselectDetailsStaff(this.addData);
      data.data.gender = JSON.stringify(data.data.gender);
      this.addData = data.data;
      this.oldmess = { ...data.data };
      this.platformAccount = data.data.platformaccount;
      this.tree.forEach((item) => {
        if (item.id == data.data.mechanismid) {
          this.placeholderCascader = item.mechanismname;
          this.mechanismId.push(item.id * 1);
          return;
        }
        if (item.platformmechanismlist) {
          item.platformmechanismlist.forEach((v) => {
            if (v.id == data.data.mechanismid) {
              this.mechanismId.push(item.id);
              this.mechanismId.push(v.id);
              this.placeholderCascader =
                item.mechanismname + "/" + v.mechanismname;
              return;
            }
            if (v.platformmechanismlist) {
              v.platformmechanismlist.forEach((v1) => {
                if (v1.id == data.data.mechanismid) {
                  this.mechanismId.push(item.id);
                  this.mechanismId.push(v.id);
                  this.mechanismId.push(v1.id);
                  this.placeholderCascader =
                    item.mechanismname +
                    "/" +
                    v.mechanismname +
                    "/" +
                    v1.mechanismname;
                  return;
                }
              });
            }
          });
        }
      });
      this.oldmess.mechanismId = this.mechanismId;
    },
    // 详情
    openDetails(row) {
      this.dialogDetails = true;
      this.addData = {
        id: row.id,
      };
      this.geteditApi();
    },
    //获取所有下级管理员包括自己
    async gainaccount() {
      this.institution = [];
      this.tree.forEach((item) => {
        let obj = {};
        obj.label = item.mechanismname;
        obj.value = item.id;
        if (item.platformmechanismlist) {
          obj.children = [];
          item.platformmechanismlist.forEach((v) => {
            let obj1 = {};
            obj1.label = v.mechanismname;
            obj1.value = v.id;
            if (v.platformmechanismlist) {
              obj1.children = [];
              v.platformmechanismlist.forEach((v1) => {
                let obj2 = {};
                obj2.label = v1.mechanismname;
                obj2.value = v1.id;
                obj1.children.push(obj2);
              });
            }
            obj.children.push(obj1);
          });
        }
        this.institution.push(obj);
      });
      let data = await this.getallAdmin();
      let num = [];
      if (this.isAdd) {
        num = data.data.filter((item) => item.platform_staff_id == null);
      } else {
        num = data.data;
      }

      this.account = num;
    },
    // 去新建
    toAddBack() {
      this.addData = {
        gender: "0",
      };
      this.placeholderCascader = "请选择机构";
      this.platformAccount = null;
      this.mechanismId = [];
      this.isAdd = true;

      this.gainaccount();
      this.isAddBackPerson = true;
      this.vesicle = false;
    },
    // 新建编辑后台人员
    async addAdmin() {
      this.isBtn = true;
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/; //设置手机号正则表达式
      let regex = /^[\u4E00-\u9FA5A-Za-z0-9]+$/; //包含汉字+英文
      if (!this.addData.staffname || this.addData.staffname == "") {
        this.errormes("姓名不能为空");
        return;
      } else if (this.addData.staffname.length < 2) {
        this.errormes("姓名应由2-20位汉字、字母或数字组成，字母区分大小写。");
        return;
      } else if (!regex.test(this.addData.staffname)) {
        this.errormes("姓名应由2-20位汉字、字母组成，字母区分大小写。");
        return;
      }
      if (!myreg.test(this.addData.mobile)) {
        //验证手机号格式是否正确
        this.errormes("手机号格式不正确");
        return;
      }
      let data = {};
      let params = {
        staffName: this.addData.staffname,
        mobile: this.addData.mobile,
        mechanismId: this.mechanismId[this.mechanismId.length - 1],
        gender: this.addData.gender,
        platformAccount: this.platformAccount,
      };
      if (this.isAdd) {
        data = await this.postaddPlatformStaff(params);
      } else {
        params.id = this.addData.id;
        data = await this.postupdatePlatformStaff(params);
      }
      this.isBtn = false;
      if (this.isAdd && data.code == 200) {
        this.isAddBackPerson = false;
        this.selectPlatformStaffList();
        this.$message({
          message: `您已成功创建新的员工【${this.addData.staffname}】`,
          type: "success",
        });
      }
      if (!this.isAdd && data.code == 200) {
        this.isAddBackPerson = false;
        this.selectPlatformStaffList();
        this.$message({
          message: data.message,
          type: "success",
        });
      }
      if (data.code != 200) {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 重新获取已有权限
    // 角色编辑
    // 获取机构列表
    async gainpostselectPlatformMechanism() {
      let data = await this.postselectPlatformMechanism();
      this.tree = data.data[0].platformmechanismlist;
      this.topLeave = {
        id: data.data[0].id,
        mechanismname: data.data[0].mechanismname,
      };
    },
    // 换页
    handleCurrentChange(val) {
      this.userList.pagenumber = val;
      this.selectPlatformStaffList();
    },
    // 查询
    seeAbout() {
      this.userList.pagenumber = 1;
      this.selectPlatformStaffList();
    },
    // 选择机构
    choseMenu(val) {
      this.choseId = val;
      this.userList.pagenumber = 1;
      this.selectPlatformStaffList();
    },
    // 员工列表
    async selectPlatformStaffList() {
      let params = {
        platformAccount: this.choseId,
        mobile: this.api.mobile,
        platformAccountName: this.api.platformAccountName,
        status: this.api.status,
        page: this.userList.pagenumber,
      };
      let data = await this.postselectPlatformStaff(params);

      let router=this.$router.history.current.path.substr(1)
        let save={...params}
        save.router=router
        this.changeSeach(save)

      this.userList.list = data.data.list;
      this.userList.total = data.data.totalrecords;
    },

    // 打开子机构
    openMenu(event, val, blo) {
      if (!blo) {
        if (event == this.isOpen[0] && val == 0) {
          event = 0;
        }
        if (event == this.isOpen[0] && val == this.isOpen[1]) {
          val = 0;
        }
      }

      this.mechanismName = "";
      this.isOpen = [0, 0];
      this.isOpen[0] = event;
      this.isOpen[1] = val;
      this.rechristenId = -1;
    },
    // 打开更多设置
    openmore(val) {
      this.moreId = val;
      this.addSecond = -1;
    },
    //
    closemore() {
      this.moreId = -1;
      this.moveId = -1;
      this.overId = -1;
    },
    // 新增子菜单
    addSonBTn(event, val) {
      this.fatherMechanismId = null;
      this.openMenu(event, val, true);
      if (val != 0) {
        this.addSecond = val;
      } else {
        this.addSecond = event;
      }
      if (event != 0) {
        this.fatherMechanismId = event;
        if (val != 0) {
          this.fatherMechanismId = val;
        }
      } else {
        this.fatherMechanismId = this.topLeave.id;
      }
      if (event == 0) {
        let num = 1;
        for (var i = 1; i < 100; i++) {
          var res = this.tree.some(function (item) {
            if (item.mechanismname == "新机构" + i) {
              return true;
            }
          });
          if (!res) {
            num = i;
            break;
          }
        }
        this.mechanismName = "新机构" + num;
      }
      this.rechristenId = -1;
    },
    errormes(val) {
      this.$message({
        message: val,
        type: "warning",
      });
      this.isBtn = false;
    },
    // 输入完毕新增子菜单
    async addSon() {
      if (
        this.mechanismName == "" &&
        this.fatherMechanismId == this.topLeave.id
      ) {
        this.$message({
          message: "机构名称不能为空",
          type: "warning",
        });
        this.addSonBTn(0, 0);
        return;
      }
      if (this.mechanismName) {
        let data = await this.postaddPlatformMechanism({
          fatherMechanismId: this.fatherMechanismId,
          mechanismName: this.mechanismName,
        });
        this.mechanismName = "";
        if (data.code == "200") {
          this.$message({
            message: "操作成功",
            type: "success",
          });
          this.gainpostselectPlatformMechanism();
          this.addSecond = -1;
        } else {
          this.$message({
            message: data.message,
            type: "warning",
          });
          return;
        }
      } else {
        this.addSecond = -1;
      }
    },
    // 切换至重命名
    rechristen(val, event) {
      this.fatherMechanismId = event;
      this.addSecond = -1;
      this.moveId = -1;
      this.rechristenId = val.id;
      this.TSname = val.mechanismname;
      this.mechanismName = val.mechanismname;
      if (val == 0) {
        this.rechristenId = this.topLeave.id;
        this.mechanismName = this.topLeave.mechanismname;
        this.TSname = this.mechanismName;
      }
    },
    // 重命名
    async RNFR() {
      if (this.TSname === this.mechanismName) {
        this.rechristenId = -1;
        return;
      }
      let params = {
        id: this.rechristenId,
        fatherMechanismId: this.fatherMechanismId,
        mechanismName: this.mechanismName,
      };
      let data = await this.postrenamingPlatform(params);
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.gainpostselectPlatformMechanism();
        this.rechristenId = -1;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
        return;
      }
    },
    // 确定移动到
    async move(val) {
      let params = {
        id: this.moveId,
        fatherMechanismId: val,
        mechanismName: this.mechanismName,
      };
      let data = await this.postmovePlatform(params);
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.gainpostselectPlatformMechanism();
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
        return;
      }
    },
    // 打开移动
    openMove(val) {
      this.addSecond = -1;
      this.rechristenId = -1;
      this.moveId = val.id;
      this.mechanismName = val.mechanismname;
    },
    // 打开删除弹出
    async del(val) {
      this.dialogName = val.mechanismname;
      this.dialogId = val.id;
      this.roleAdminNum = 1;
      if (val.platformmechanismlist.length > 0) {
        this.roleAdminNum = 3;
      }
      let data = await this.postselectPlatformStaff({
        platformAccount: val.id,
      });
      if (data.data.list.length > 0) {
        this.roleAdminNum = 2;
      }
      this.dialogVisibleDeleted = true;
    },
    //
    async confirmDel() {
      let data = await this.getdeleteMechanism({ id: this.dialogId });
      if (data.code == "200") {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.gainpostselectPlatformMechanism();
        this.dialogVisibleDeleted = false;
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.backMenu {
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 10px;
  display: flex;
  .structure-tree {
    border-right: 1px solid #eaeaea;
    width: 250px;
    height: 100%;
    padding-top: 20px;
    .addone {
      color: #06b7ae;
      padding: 0 26px 20px;
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 22px;
        height: 22px;
        margin-right: 5px;
      }
    }
    .tree-box {
      overflow: auto;
      height: 90%;
      overflow-x: visible;
      width: 600px;
    }
    .tree-box::-webkit-scrollbar {
      display: none;
    }
    .tree {
      cursor: pointer;
      font-size: 14px;
      line-height: 30px;
      color: #383b46;
      .head {
        display: flex;
        align-items: center;
        padding-left: 15px;
        justify-content: space-between;
        position: relative;
        width: 250px;
        img {
          margin-right: 15px;
        }
      }
      input {
        height: 30px;
        box-sizing: border-box;
        border: 1px solid #ccc;
        outline: none;
        border-radius: 4px;
      }
      input:focus {
        border: 1px solid #06b7ae;
      }
      .change-first {
        width: 157px;
        font-size: 14px;
      }
      .change-second {
        width: 145px;
        font-size: 14px;
      }
      .first-input {
        width: 187px;
        margin-left: 30px;
        font-size: 14px;
      }
      .second-input {
        width: 150px;
        margin-left: 66px;
        font-size: 14px;
      }

      .first-list {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        padding-left: 25px;
        align-items: center;
        position: relative;
        width: 250px;
        div {
          display: flex;
          align-items: center;
          img {
            margin-right: 12px;
          }
        }
      }
      .second-list {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        padding-left: 35px;
        align-items: center;
        position: relative;
        width: 250px;
        div {
          display: flex;
          align-items: center;
          img {
            margin-right: 12px;
          }
        }
      }
      .three-list {
        display: flex;
        justify-content: space-between;
        padding-right: 15px;
        padding-left: 66px;
        align-items: center;
        position: relative;
        width: 250px;
        .three-dis {
          display: flex;
          align-content: center;
          .none-son {
            width: 15px;
            height: 15px;
            margin-right: 0;
          }
        }
      }
    }
  }
  .staff {
    flex: 1;
  }
}
.nabla {
  width: 10px;
  height: 10px;
  padding: 5px;
  box-sizing: content-box;
  cursor: pointer;
  -moz-transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
}
.rotate {
  -moz-transform: rotate(0);
  -webkit-transform: rotate(0);
}
.none-son {
  width: 20px;
  height: 20px;
  margin-right: 12px;
}
.more {
  width: 20px;
  height: 20px;
  z-index: 5;
  cursor: pointer;
}
.chose {
  background-color: rgba($color: #06b7ae, $alpha: 0.1);
  color: #06b7ae;
}
.more-function {
  position: absolute;
  // border: 1px solid #ccc;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  top: 30px;
  left: 180px;
  display: block !important;
  z-index: 9;
  line-height: 30px;
  width: 170px;
  // overflow: hidden;
  background-color: #fff;
  font-size: 12px;
  button {
    padding: 0 17px;
    display: block;
    width: 100%;
    line-height: 30px;
    border: none;
    background-color: #fff;
    text-align: start;
    margin-left: 0;
  }
  button:hover {
    background-color: #f5f6f7;
    color: #383b46;
  }
  .move-list {
    position: absolute;
    background-color: #fff;
    // border: 1px solid #ccc;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    top: 0;
    width: 170px;
    border-radius: 6px;
    left: 168px;
    height: 210px;
    overflow-y: scroll;
    display: block !important;
    div {
      display: block !important;
      padding: 0 10px;
      cursor: pointer;
    }
  }
  // flex-wrap: wrap;
}
.mouse-over {
  color: #06b7ae;
}
.staff {
  padding: 0 24px;
  box-sizing: border-box;
  display: block;
}
.sch {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 30px;
  line-height: 30px;
  padding: 22px 0;
  box-sizing: content-box;
  > div {
    display: flex;
    height: 30px;
  }
  button {
    padding: 0 12px;
    background-color: #06b7ae;
    color: #fff;
    border: none;
    height: 30px;
    margin-left: 20px;
  }
}
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin: 0 15px;
    color: #1abca9;
    cursor: pointer;
  }
  span {
    margin: 0 15px;
  }
}
.merch-page {
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: right;
}
.dialog-div {
  line-height: 32px;
  height: 32px;
  color: #333;
  font-size: 14px;
}
.sch-input {
  width: 220px;
  height: 32px;
  margin-right: 20px;
}
.bubble-box {
  position: relative;
  .bubble {
    position: absolute;
    right: 0;
    bottom: -150px;
    background-color: #fff7e8;
    padding: 10px;
    border-radius: 4px;
    i {
      color: #ff7d00;
    }
  }
}
.cancel {
  background-color: #fff7e8;
  color: #333;
  margin-top: 5px;
  height: 20px;
  padding: 0;
  width: 60px;
  line-height: 26px;
  height: 26px;
}
.affirmclose {
  margin-top: 5px;
  padding: 0;
  width: 60px;
  line-height: 26px;
  background-color: #06b7ae;
  border: none;
  color: #fff;
  height: 26px;
}
.initial {
  font-size: 12px;
  color: #06b7ae;
  cursor: pointer;
}
.call-off {
  background-color: #f5f5f5;
  color: #383b46;
  border: none;
}
::v-deep .el-input--suffix {
  height: 32px;
  width: 220px;
}
::v-deep .el-input__inner {
  width: 100%;
  height: 100%;
}
::v-deep .el-table th.el-table__cell.is-leaf,
.el-table td.el-table__cell {
  background-color: #f2f3f5 !important;
}
::v-deep .el-table--border {
  border-radius: 10px;
}
::v-deep .el-input__icon {
  line-height: 30px;
}
::v-deep .el-popover__reference {
  padding: 0 !important;
  border: none;
  margin-left: 5px;
  color: #1abca9;
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
.exceed {
  cursor: default;
}
</style>
